<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Framework Update Report: Show All Archives</h3>

    <img srcset="/docimages/track_changes.png 1.8x" class="k-help-img float-right">
    <p class="k-no-clear">The Track Changes - Tree View for a comparison between frameworks allows the user to navigate changes using a legend as a reference point. You can navigate to this view from your <span v-html="link('framework_notification', 'Framework Notification Preference')"></span> email or from the <span v-html="link('framework_update', 'Framework Update Report')"></span> where you see the Track Changes <nobr><v-icon small>fa-solid fa-right-left</v-icon></nobr> icon.
    </p>

   
    <p>Some pointers for navigating the track changes tree view are:
        <ol>
            <li>Select any parent or child item to collapse or expand your view to track changes.</li>
            <li>Items with a half circle icon will show the updates as highlighted text.</li>
            <li>Verify the frameworks you are comparing by looking at the framework title in the top left and the archived framework listed in the bottom left. To switch the frameworks you are comparing, choose the SHOW CHANGES TO button.</li>
            <li>To SHOW CHANGES IN TABLE view, use the table icon in the footer.</li>
            <li>The DONE TRACKING CHANGES icon in the footer, will allow you to navigate the current framework again and remove the track changes footer.</li>
            <li>Selecting the key icon, will pop-up the legend for easy reference.</li>
        </ol>
    </p>
    <img srcset="/docimages/track_example.png " class="k-help-img block">
    
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>